import * as React from 'react';
import {useCallback} from 'react';

const context = React.createContext(undefined);

export function DialplanEditorContext({children, dialplan: initDialplan}) {
    const [isEditMode, _setIsEditMode] = React.useState(false);
    const [_dialplan, setDialplan] = React.useState(initDialplan);

    const setIsEditMode = useCallback((value) => {
        setDialplan(initDialplan); // reset any changes
        _setIsEditMode(value);
    }, [initDialplan]);

    const dialplan = isEditMode ? _dialplan : initDialplan;

    return <context.Provider
        value={{isEditMode, setIsEditMode, dialplan, setDialplan}}>
            {children}
    </context.Provider>
}

export function useDialplanEditorContext() {
    return React.useContext(context);
}

import * as React from 'react';
import {Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import CallEndIcon from "@mui/icons-material/CallEnd";

function Hangup() {
    return (
        <Grid container direction={"column"} alignItems={"center"}>
            <CallEndIcon sx={{fontSize: 70}} color={"error"}/>
            <Grid item xs={12} sx={{mb: 2, mt:2}}>
                <Typography variant="body" display={'inline'} align={"center"}>
                    La chiamata viene terminata e ricevi il resoconto in app.
                </Typography>
            </Grid>
        </Grid>
    )
}


Hangup.title = "Termina la chiamata"
Hangup.description = "Usa questo modulo per terminare la chiamata"
Hangup.default = {
    type: 'HANGUP'
}


export default Hangup;

import * as React from 'react';
import {Chip, Grid, Stack} from "@mui/material";
import FaceIcon from '@mui/icons-material/Face';
import Typography from "@mui/material/Typography";


function DialTargets({targets, setTargets, isEditMode}) {
    const groupedTargets = targets.reduce((acc, target) => {
        if (!acc[target.delay]) {
            acc[target.delay] = []
        }
        acc[target.delay].push(target)
        return acc
    }, {})

    const onTargetDelete = (targetToDelete) => {
        return () => {
            const nextTargets = targets.filter(it => it.name !== targetToDelete.name || it.delay !== targetToDelete.delay)
            setTargets(nextTargets);
        }
    }

    if (targets.length === 0) {
        return (
            <Grid container sx={{
                minWidth: 120,
                justifyContent: "center",
                alignContent: "center",
                width: "100%"
            }}>
                <Typography variant="subtitle1" sx={{color: "red"}}>
                    Nessun collaboratore selezionato
                </Typography>
            </Grid>
        );
    }

    return (
        <Stack style={{width: "100%"}}>
            {Object.keys(groupedTargets).sort().map((delay) => {
                const isSingular = groupedTargets[delay].length <= 1
                const when = delay === "0" ? "immediatamente" : `dopo ${delay} secondi`

                const description = (isSingular ? "Squilla " : "Squillano ")
                    + when
                    + ":";

                return (
                    <Stack key={delay} sx={{my: 1}}>
                        <Typography variant="subtitle1" sx={{fontWeight: "500"}}>
                            {description}
                        </Typography>
                        <Grid container sx={{mb: 3}} justifyContent={"flex-start"}>
                            {groupedTargets[delay].map((target, index) => (
                                <Grid item xs={12} sm={4} md={4} key={index} sx={{my: 1}}>
                                    <DialTarget target={target}
                                                onDelete={onTargetDelete(target)}
                                                isEditMode={isEditMode}/>
                                </Grid>
                            ))}
                        </Grid>
                    </Stack>
                )
            })}
        </Stack>
    );
}

function DialTarget({target, onDelete, isEditMode}) {
    return (
        <Chip icon={<FaceIcon/>}
              label={target.name}
              variant="outlined"
              color="primary"
              onDelete={isEditMode ? onDelete : undefined}/>
    )
}

export default DialTargets;

import * as React from "react";
import { useCallback } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import { CircularProgress } from "@mui/material";


export default function DialplanEditBar({ onCancel, onSave: _onSave, onEdit, isEdit }) {
  const [isLoding, setIsLoading] = React.useState(false);
  const onSave = useCallback(async () => {
    if (isLoding) {
      return;
    }
    setIsLoading(true);

    try {
      await _onSave();
    } finally {
      setIsLoading(false);
    }
  }, [_onSave, isLoding]);

  return (
    <AppBar position="fixed" color="white" sx={{ top: "auto", bottom: 0 }}>
      <Container maxWidth="md">
        <Box sx={{
          minHeight: 60,
          flex: 1,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: "1rem",
          height: "100%"
        }}>
          <Stack flex={1}></Stack>
          {!isEdit && <Button variant="outlined" startIcon={<EditIcon/>} color="primary" onClick={onEdit}>
            Modifica risponditore
          </Button>
          }
          {isEdit && <>
            <Button variant="outlined" startIcon={<CloseIcon/>} color={"error"} onClick={onCancel}>
              Annulla
            </Button>
            <Button variant="outlined"
                    endIcon={isLoding ? <CircularProgress size={20}/> : <SaveIcon/>}
                    color={"primary"}
                    onClick={onSave}
                    disabled={isLoding}>
              Salva
            </Button>
          </>}
        </Box>
      </Container>
    </AppBar>
  );
}

import * as React from 'react';
import {Box, Button, Grid, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import TimeIntervalsPicker from "./TimeIntervalsPicker";
import DialplanNode, {DialplanNodeIndexContext, DialplanNodePropContext, useDialplanNodeContext} from "./DialplanNode";
import Accordion from "../Accordion";
import Paper from "@mui/material/Paper";
import AddTimeIntervals from "./AddTimeIntervals";
import {useDialplanEditorContext} from './DialplanEditorContext';
import Hangup from "./HangupNode";
import TtsAnnounce from "./TtsAnnounce";

function TimeSwitchNode() {
    const {node: {cases}} = useDialplanNodeContext();
    const {isEditMode} = useDialplanEditorContext();
    const [openAccordion, setOpenAccordion] = React.useState(false);
    return (
        <Grid container direction={"column"}>
            <Grid item xs={12}>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }}>
                    <Typography variant="h6" align="left">
                        ⏲️ Smistamento orario
                    </Typography>
                </div>
            </Grid>
            <Grid item xs={12} sx={{mt: 2}}>
                <Typography variant="body" display={'inline'}>
                    Determina il flusso di risposta in base al giorno e orario della chiamata.
                </Typography>
            </Grid>
            <DialplanNodePropContext prop={"cases"}>
                {isEditMode && <AddTimeSwitchNodeCase/>}
                <Grid item container sx={{mt: 2,}}>
                    {cases.map((it, index) => (
                            <DialplanNodeIndexContext key={index} index={index}>
                                <TimeSwitchNodeCase/>
                            </DialplanNodeIndexContext>
                        )
                    )}
                    {cases.length === 0 && !isEditMode && <Typography>
                        Al momento non sono presenti casistiche basate sull'orario, modifica il piano di risposta per
                        aggiungerle
                    </Typography>}

                </Grid>
            </DialplanNodePropContext>
            <Grid item container sx={{mt: 4}}>
                <Accordion
                    title={"🟥"}
                    description={"In tutti gli altri casi"}
                    expanded={openAccordion}
                    onChange={() => setOpenAccordion(!openAccordion)}>
                    <DialplanNode prop="default"/>
                </Accordion>
            </Grid>
        </Grid>
    )
}


export function AddTimeSwitchNodeCase() {
    const {node: cases, setNode} = useDialplanNodeContext();
    const addNode = () => {
        setNode([...cases, {intervals: [], node: Hangup.default}])
    }
    return <Grid
        onClick={addNode}
        container
        sx={{
            mt: 2,
            width: "100%",
            justifyContent: "center",
            alignContent: "center",
            minHeight: 70,
            backgroundColor: "#f5f5f5",
            borderRadius: 4,
            border: "1px dashed #ccc",
            cursor: "pointer",
            "&:hover": {
                backgroundColor: "#e5e5e5"
            },
        }}>
        <Box>
            <Typography variant="body1" sx={{color: "#999", fontSize: 14}}>
                Clicca per aggiungere una casistica
            </Typography>
        </Box>
    </Grid>
}

function TimeSwitchNodeCase() {
    const {node, setNode, setNodeChild} = useDialplanNodeContext();
    const {isEditMode} = useDialplanEditorContext();
    const [openAccordion, setOpenAccordion] = React.useState(false);
    return (
        <Stack sx={{mt: 2, width: "100%"}}>
            <Paper elevation={3}>
                <Grid item container sx={{mt: 1, p: 1}}>
                    {isEditMode && <Stack flex={1} direction="row" justifyContent={'flex-end'}>
                        <Button color="error" onClick={() => setNode(null)}>
                            Rimuovi casistica
                        </Button>
                    </Stack>}
                    <TimeIntervalsPicker intervals={node?.intervals || []}
                                         setIntervals={setNodeChild("intervals")}
                                         isEditMode={isEditMode}
                                         showDayOff={false}/>
                    {isEditMode && <AddTimeIntervals intervals={node.intervals}
                                                     setIntervals={setNodeChild("intervals")}
                                                     daysLabel={"Seleziona i giorni in cui applicare questo intervallo"}
                                                     startTimeLabel={"Seleziona l'orario di inizio"}
                                                     endTimeLabel={"Seleziona l'orario di fine"}
                                                     sx={{mt: 2}}/>
                    }
                </Grid>
                <Grid item container sx={{mt: 4}}>
                    <Accordion title={"🟦"}
                               description={"Configura la risposta"}
                               expanded={openAccordion}
                               onChange={() => setOpenAccordion(!openAccordion)}>
                        <DialplanNode prop="node"/>
                    </Accordion>
                </Grid>
            </Paper>
        </Stack>
    );
}

TimeSwitchNode.title = "Smistamento orario"
TimeSwitchNode.description = "Rispondi in base al giorno e orario della chiamata."
TimeSwitchNode.default = {
    type: "TIME_SWITCH",
    cases: [],
    default: TtsAnnounce.default
}

export default TimeSwitchNode;

import * as React from 'react';
import {Chip, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import arrayEquals from "../../utils/arrayEquals";

function leadingZero(n) {
    return n < 10 ? "0" + n : n;
}

const italianDays = {
    0: "Domenica",
    1: "Lunedì",
    2: "Martedì",
    3: "Mercoledì",
    4: "Giovedì",
    5: "Venerdì",
    6: "Sabato",
}
const orderedDays = [1, 2, 3, 4, 5, 6, 0];

function TimeIntervalsPicker({intervals, setIntervals, showDayOff, isEditMode}) {
    const dayToIntervals = Object.keys(italianDays).reduce((acc, day) => {
        acc[day] = intervals.filter(interval => interval.days.includes(parseInt(day))).map(interval => {
            const startTime = interval.startTime;
            const endTime = interval.endTime;
            return {startTime, endTime}
        });
        return acc
    }, {});

    const onIntervalDelete = (dayOrdinal, startTime, endTime) => {
        const nextDayToIntervals = {
            ...dayToIntervals,
            [dayOrdinal]: dayToIntervals[dayOrdinal].filter(interval => !arrayEquals(interval.startTime, startTime) || !arrayEquals(interval.endTime, endTime))
        };

        setIntervals(Object.keys(nextDayToIntervals).reduce((acc, day) => {
            const intervals = nextDayToIntervals[day];
            if (intervals.length > 0) {
                intervals.forEach(interval => {
                    acc.push({
                        startTime: interval.startTime,
                        endTime: interval.endTime,
                        days: [parseInt(day)]
                    })
                })
            }
            return acc
        }, []))
    }

    const daysToShow = showDayOff ? orderedDays : orderedDays.filter(day => dayToIntervals[day].length > 0);

    if (daysToShow.length === 0) {
        return (
            <Grid container sx={{
                justifyContent: "center",
                alignContent: "center",
                width: "100%",
                minHeight: 70,
            }}>
                <Typography variant="subtitle1" sx={{color: "red"}}>
                    Nessun giorno selezionato
                </Typography>
            </Grid>
        );
    }

    return (
        <Grid container direction={"column"}>
            {daysToShow.map(dayOrdinal => <DayIntervals dayOrdinal={dayOrdinal}
                                                        dayIntervals={dayToIntervals[dayOrdinal]}
                                                        onIntervalDelete={onIntervalDelete}
                                                        isEditMode={isEditMode}
                                                        key={dayOrdinal}/>
            )}
        </Grid>
    )
}

function DayIntervals({dayOrdinal, dayIntervals, onIntervalDelete, isEditMode}) {
    return (
        <Grid container sx={{mt: 1.5}}>
            <Grid item xs={6}>
                <Typography variant="body" sx={{fontWeight: "500", ml: {xs: 1, md: 4}}}>
                    {italianDays[dayOrdinal]}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Grid container>
                    {
                        dayIntervals.length === 0 &&
                        (
                            <Grid item xs={12} sm={6} md={4} sx={{mt: 1, justifyContent: "center", display: "flex"}}>
                                <Chip  variant="outlined" sx={{color: "red"}} label="CHIUSO"></Chip>
                            </Grid>
                        )
                    }
                    {dayIntervals.map((interval, index) => {
                        const {startTime, endTime} = interval;
                        const label = `${leadingZero(startTime[0])}:${leadingZero(startTime[1])} - ${leadingZero(endTime[0])}:${leadingZero(endTime[1])}`;
                        return (
                            <Grid item xs={12} sm={6} md={4} key={index}
                                  sx={{mt: 1, display: "flex", justifyContent: "center"}}>
                                <Chip label={label}
                                      variant="outlined"
                                      onDelete={isEditMode ? () => onIntervalDelete(dayOrdinal, startTime, endTime) : undefined}/>
                            </Grid>
                        )
                    })}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default TimeIntervalsPicker;

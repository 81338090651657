export default function parseSeconds(seconds) {
    if (seconds < 60) {
        return seconds + " secondi";
    }
    if (seconds >= 60 && seconds < 120) {
        return "1 minuto" + (seconds % 60 > 0 ? " e " + (seconds % 60) + " secondi" : "");
    }

    return Math.floor(seconds / 60) + " minuti" + (seconds % 60 > 0 ? " e " + (seconds % 60) + " secondi" : "");
}

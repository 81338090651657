import * as React from "react";
import { useCallback } from "react";
import { Divider, Grid, IconButton, List, ListItem, ListItemText, Paper, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import DialplanNode, { useDialplanNodeContext } from "./DialplanNode";
import Accordion from "../Accordion";
import { useDialplanEditorContext } from "./DialplanEditorContext";
import TtsAnnounce from "./TtsAnnounce";
import { Delete } from "@mui/icons-material";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import { toast } from "react-toastify";
import Button from "@mui/material/Button";


function ListRouting() {
  const { isEditMode } = useDialplanEditorContext();
  const { node, setNodeChild } = useDialplanNodeContext();
  const [openAccordion, setOpenAccordion] = React.useState(false);
  const { list } = node;

  return (
    <Grid container direction={"column"}>
      <Grid item xs={12}>
        <div style={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
        }}>

          <Typography variant="h6" align="left">
            ↔️ Risposta in base al numero chiamante
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12} sx={{ mt: 2 }} justifyContent="flex-start">
        <Typography variant="body" display={"inline"}>
          Definisci le azioni da eseguire in base alla presenza o meno del numero chiamante nella seguente lista.
        </Typography>
      </Grid>
      <Grid container fullWidth sx={{ mt: 2, }}>
        <ShowList list={list} setList={setNodeChild("list")} isEditMode={isEditMode}/>
      </Grid>
      <Grid>
        {isEditMode && <AddNumberToList list={list} setList={setNodeChild("list")} sx={{ mt: 2 }}/>}
      </Grid>
      <Grid item container sx={{ mt: 4 }}>
        <Accordion title={"✅"}
                   description={"Risposta se il numero chiamante è presente nella lista"}
                   expanded={openAccordion === "open"}
                   onChange={() => setOpenAccordion(openAccordion === "open" ? false : "open")}>
          <DialplanNode prop="inList"/>
        </Accordion>
        <Accordion title={"🟥"}
                   description={"Risposta se il numero chiamante non è presente nella lista"}
                   expanded={openAccordion === "closed"}
                   onChange={() => setOpenAccordion(openAccordion === "closed" ? false : "closed")}
        >
          <DialplanNode prop="notInList"/>
        </Accordion>
      </Grid>
    </Grid>
  );
}

ListRouting.description = "Permette di definire delle azioni in base alla presenza o meno del numero chiamante in una lista";
ListRouting.title = "Rispondi in base al numero chiamante";
ListRouting.default = {
  type: "LIST_ROUTING",
  list: [],
  inList: { ...TtsAnnounce.default, text: "Complimenti, il tuo numero è presente nella lista" },
  notInList: { ...TtsAnnounce.default, text: "Mi dispiace, il tuo numero non è presente nella lista" },
};

export default ListRouting;

function ShowList({ list, setList, isEditMode }) {
  const deleteNumber = useCallback((index) => {
    const newList = removeDuplicates(list.filter((_, i) => i !== index));
    setList(newList);
  }, [list, setList]);

  if (list.length === 0) {
    return (
      <Paper elevation={1} fullWidth sx={{ width: "100%", }}>
        <Grid container sx={{
          justifyContent: "center",
          alignContent: "center",
          width: "100%",
          minHeight: 70,
        }}>
          <Typography variant="subtitle1" sx={{ color: "red" }}>
            Nessun numero presente nella lista
          </Typography>
        </Grid>
      </Paper>
    );
  }

  return (
    <Grid container fullWidth>
      <Paper elevation={1} fullWidth sx={{ width: "100%", }}>
        <Box component="nav" sx={{ width: "100%", maxHeight: 300, overflow: "auto" }}>
          <List component="nav" sx={{ width: "100%", }}>
            {list.map((number, index) => (
              <React.Fragment key={index}>
                <ListItem secondaryAction={
                  isEditMode && <IconButton edge="end" onClick={() => deleteNumber(index)}>
                    <Delete color={"error"}/>
                  </IconButton>
                }>
                  <ListItemText primary={number}/>
                </ListItem>
                {index < list.length - 1 && <Divider/>}
              </React.Fragment>
            ))}
          </List>
        </Box>
      </Paper>
    </Grid>
  );
}


function sanitize(input) {
  if (input.trim().toLowerCase() === "anonymous") {
    return "anonymous";
  }

  const sanitized = input.replace(/[^0-9+*]/g, "");
  if (sanitized === "") {
    return;
  }

  return sanitized.startsWith("+") ? sanitized : "+39" + sanitized;
}

function AddNumberToList({ list, setList, sx }) {
  const [input, setInput] = React.useState("");

  const addNumber = () => {
    const sanitized = sanitize(input);

    if (!sanitized) {
      toast.error("Inserisci un numero valido, es. +393331234567",
        { position: "bottom-center", autoClose: 3000 });
      return;
    }

    const newList = [...list, sanitized];
    setList(removeDuplicates(newList));
    setInput("");

  };

  return (
    <Paper elevation={3} sx={{ p: 2, ...sx }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={9}>
          <TextField
            variant="outlined"
            placeholder="+393331234567"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onSubmit={addNumber}
            onKeyUp={(e) => e.key === "Enter" && addNumber()}
            fullWidth
            size={"small"}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button variant="contained" color="success" onClick={addNumber} fullWidth startIcon={<AddIcon/>}>
            Aggiungi
          </Button>
        </Grid>
      </Grid>
      <Typography sx={{ color: "text.secondary", mt: 2 }} variant="body2">
        🇮🇹 <b>Numero Internazionale:</b> Inserisci il prefisso internazionale.
        Esempio: <b>+39</b> per i numeri italiani o <b>+44</b> per i numeri inglesi.
      </Typography>
      <Typography sx={{ color: "text.secondary", mt: 2 }} variant="body2">
        💡 <b>Carattere Jolly:</b> Puoi utilizzare il carattere jolly <b>*</b> per definire una regola che si applica
        a tutti i numeri. Esempio: <b>+39*</b> corrisponde a tutti i numeri italiani.
      </Typography>
      <Typography sx={{ color: "text.secondary", mt: 2 }} variant="body2">
        😷 <b>Numero Anonimo:</b> Se vuoi aggiungere alla lista i numeri anonimi, inserisci <b>anonymous</b>.
      </Typography>
    </Paper>
  );
}

function removeDuplicates(array) {
  return array.filter((item, index) => array.indexOf(item) === index);
}

import {Stack} from '@mui/system';
import * as React from 'react';
import {useDialplanEditorContext} from "./DialplanEditorContext";
import {DialplanNodeSelection} from './DialplanNodeSelection';
import {NODES} from "./Nodes";

const DialplanNodeContext = React.createContext({
    setNode: () => {
    },
    setNodeChild: (child) => (value) => {
    },
    node: null,
});

export const useDialplanNodeContext = () => {
    return React.useContext(DialplanNodeContext)
}


export function DialplanRootNode({children}) {
    const {setDialplan, dialplan} = useDialplanEditorContext();
    const setNodeChild = (child) => (value) => {
        setNodeChild({...dialplan, [child]: value})
    }
    return <DialplanNodeContext.Provider value={{
        node: dialplan,
        setNode: setDialplan,
        setNodeChild
    }}>
        {children}
    </DialplanNodeContext.Provider>
}


export function DialplanNodePropContext({prop, children}) {
    const {node: parent, setNode: setParentNode} = useDialplanNodeContext();
    const node = parent[prop];
    const setNode = (value) => {
        setParentNode({...parent, [prop]: value});
    }
    const setNodeChild = (child) => (value) => {
        setNode({...node, [child]: value})
    }
    return <DialplanNodeContext.Provider value={{
        setNodeChild,
        setNode,
        node
    }}>
        {children}
    </DialplanNodeContext.Provider>
}


export function DialplanNodeIndexContext({index, children}) {
    const {node: parent, setNode: setParentNode} = useDialplanNodeContext();
    if (!(parent instanceof Array)) {
        throw new Error('DialplanNodeIndexContext must be used with an array prop as context')
    }
    const node = parent[index];
    const setNode = (value) => {
        const newParentNode = [...parent.slice(0, index), value, ...parent.slice(index + 1)];
        setParentNode(newParentNode.filter(x => !!x));
    }
    const setNodeChild = (child) => (value) => {
        setNode({...node, [child]: value})
    }
    return <DialplanNodeContext.Provider value={{
        setNodeChild,
        setNode,
        node
    }}>
        {children}
    </DialplanNodeContext.Provider>
}


export default function DialplanNode({prop}) {
    const {isEditMode} = useDialplanEditorContext();
    const {node: parent} = useDialplanNodeContext();
    const node = parent[prop];
    const Node = NODES[node?.type]

    return <DialplanNodePropContext prop={prop}>
        <Stack container sx={{position: 'relative'}}>
            {isEditMode && Node?.canBeReplaced !== false && <DialplanNodeSelection/>}
            {node && <Node/>}
        </Stack>
    </DialplanNodePropContext>
}

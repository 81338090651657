import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Typography
} from "@mui/material";
import { useState } from "react";
import { useDialplanNodeContext } from "./DialplanNode";
import { NODES } from "./Nodes";

const SELECTABLE_NODES = {
  "TTS_ANNOUNCE": NODES.TTS_ANNOUNCE,
  "RECORD": NODES.RECORD,
  "HANGUP": NODES.HANGUP,
  "LIST_ROUTING": NODES.LIST_ROUTING,
  "TIME_SWITCH": NODES.TIME_SWITCH,
  "DIAL": NODES.DIAL,
  "IVR": NODES.IVR,
};

export function DialplanNodeSelection() {
  const [open, setState] = useState(false);
  const { node, setNode } = useDialplanNodeContext();
  return <Stack direction="row" justifyContent={!node ? "center" : "flex-end"} sx={{mb:2}}>
    <Button color="primary" variant="outlined"
            onClick={() => setState(true)}>{node ? "MODIFICA RISPOSTA" : "AGGIUNGI COMPORTAMENTO"}</Button>
    <Dialog
      open={open}
      onClose={() => setState(false)}
      fullWidth={true}
      maxWidth="sm">
      <DialogTitle>Scegli comportamento</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Seleziona una tipologia di comporamento
        </DialogContentText>
        <FormControl fullWidth={true} sx={{ mt: 2 }}>
          <Typography variant="body1" sx={{ mt: 3 }}>
            Comportamento
          </Typography>
          <Select value={node?.type} onChange={e => {
            const type = e.target.value;
            if (type !== node?.type) {
              setNode(SELECTABLE_NODES[type]?.default);
              setState(false);
            }
          }}>
            {Object.values(SELECTABLE_NODES).map(n =>
              <MenuItem key={n.default?.type} value={n.default?.type}>{n?.title}</MenuItem>
            )}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setState(false)}>Chiudi</Button>
      </DialogActions>
    </Dialog>
  </Stack>;
}

import * as React from "react";
import DialplanBox from "../components/DialplanBox";
import { useDialplanRepository } from "../repository/DialplanRepository";
import Typography from "@mui/material/Typography";

function MainScreen() {
    const dialplanRepository = useDialplanRepository();

    if (dialplanRepository.error) {
        return (
            <div style={{
                flexDirection: "column",
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: 500
            }}>
                <Typography variant="h6" color="error">
                    Errore nel caricamento dei dati
                </Typography>
                <Typography variant="body">
                    <a href={"#"} onClick={() => window.location.reload()}>Ricarica</a>
                </Typography>
            </div>
        )
    }

    return (
        <>
            {!dialplanRepository.isLoading && <DialplanBox dialplan={dialplanRepository.data}
                                                           setDialplan={dialplanRepository.putMutation.mutateAsync}/>
            }
        </>
    )
}

export default MainScreen;

import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {ConfirmProvider} from "material-ui-confirm";
import {ToastContainer} from 'react-toastify';
import MainScreen from "./screens/MainScreen";
import RepositoryContext from "./repository/RepositoryContext";
import Box from "@mui/material/Box";
import useAuthentication from "./service/AuthenticationService";
import LoginScreen from "./screens/LoginScreen";

import 'react-toastify/dist/ReactToastify.css';

const theme = createTheme({
    typography: {
        fontFamily: 'Poppins',
        fontWeightRegular: '400',
        fontWeightMedium: '500',
        fontWeightBold: '500',
        title: {
            fontWeight: '800',
            fontSize: '1.25rem',
            color: '#1565c0'
        }
    },
    palette: {
        white: {
            main: '#ffffff',
            contrastText: '#1565c0'
        }
    },
});

function App() {
    const authentication = useAuthentication();
    return (
        <ThemeProvider theme={theme}>
            <ConfirmProvider>
                <RepositoryContext>
                    <CssBaseline/>
                    <ToastContainer style={{marginBottom: 30}}/>
                    {authentication.isLogged ?
                        <MainScreen/>
                        : <LoginScreen/>
                    }

                    <Box sx={{marginBottom: 10}}/>
                </RepositoryContext>
            </ConfirmProvider>
        </ThemeProvider>
    )
}

export default App;

import queryString from 'query-string';


function useAuthentication() {
    const parsedQuery = queryString.parse(window.location.search)
    const phoneUuid = parsedQuery?.phoneUuid;
    const token = parsedQuery?.token;

    if (token) {
        localStorage.setItem('token', token);
        localStorage.setItem('phoneUuid', phoneUuid);
        return {isLogged: true, token: token, phoneUuid: phoneUuid};
    }

    const storedToken = localStorage.getItem('token');
    const storedPhoneUuid = localStorage.getItem('phoneUuid');
    if (storedToken) {
        return {isLogged: true, token: storedToken, phoneUuid: storedPhoneUuid};
    }

    return {isLogged: false, token: null, phoneUuid: null};
}

export default useAuthentication;

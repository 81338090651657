import * as React from 'react';
import {Box, Grid, TextField} from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {MobileTimePicker} from '@mui/x-date-pickers/MobileTimePicker';
import moment from "moment";


function AddTimeIntervals({
                              intervals,
                              setIntervals,
                              daysLabel,
                              startTimeLabel,
                              endTimeLabel,
                              sx
                          }) {
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [days, setDays] = React.useState([]);
    const [startTime, setStartTime] = React.useState([9, 0]);
    const [endTime, setEndTime] = React.useState([19, 0]);
    const [isTimeValid, setIsTimeValid] = React.useState(true);

    const closeModalAndReset = () => {
        setIsModalOpen(false);
        setDays([]);
        setStartTime([9, 0]);
        setEndTime([19, 0]);
    }

    const onAddInterval = () => {
        setIntervals([
            ...intervals,
            {
                days,
                startTime,
                endTime
            }
        ]);
        closeModalAndReset();
    }

    return (
        <>
            <Grid container
                  sx={{
                      width: "100%",
                      justifyContent: "center",
                      alignContent: "center",
                      minHeight: 70,
                      backgroundColor: "#f5f5f5",
                      borderRadius: 4,
                      border: "1px dashed #ccc",
                      cursor: "pointer",
                      "&:hover": {
                          backgroundColor: "#e5e5e5"
                      },
                      ...sx
                  }} onClick={() => setIsModalOpen(true)}>
                <Box>
                    <Typography variant="body1" sx={{color: "#999", fontSize: 14}}>
                        Clicca per aggiungere una fascia oraria in cui la tua attvità è aperta
                    </Typography>
                </Box>
            </Grid>
            <Dialog open={isModalOpen}
                    onClose={closeModalAndReset}
                    fullWidth={true}
                    maxWidth="sm">
                <DialogTitle>Aggiunta orario</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Inserisci una nuova fascia oraria per la tua attività
                    </DialogContentText>
                    <FormControl fullWidth={true} sx={{mt: 2}}>
                        <SelectDays label={daysLabel} selected={days} setSelected={setDays}/>

                        <Typography variant="body1" sx={{mt: 3}}>
                            {startTimeLabel}
                        </Typography>
                        <SelectTime label={startTimeLabel}
                                    value={startTime}
                                    setValue={setStartTime}
                                    sx={{mt: 2}}/>

                        <Typography variant="body1" sx={{mt: 3}}>
                            {endTimeLabel}
                        </Typography>
                        <SelectTime label={endTimeLabel}
                                    minTime={startTime}
                                    value={endTime}
                                    setValue={setEndTime}
                                    onError={(err) => setIsTimeValid(err === null)}
                                    sx={{mt: 2}}/>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeModalAndReset}>Cancella</Button>
                    <Button onClick={onAddInterval} disabled={days.length === 0 || !isTimeValid}>Aggiungi</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const italianDays = {
    1: "Lunedì",
    2: "Martedì",
    3: "Mercoledì",
    4: "Giovedì",
    5: "Venerdì",
    6: "Sabato",
    0: "Domenica",
}
const orderedDays = [1, 2, 3, 4, 5, 6, 0];


function SelectDays({label, selected, setSelected}) {
    const handleChange = (event) => {
        const {
            target: {value},
        } = event;
        setSelected(typeof value === 'string' ? value.split(',') : value);
    };

    return (
        <>
            <InputLabel>{label}</InputLabel>
            <Select
                multiple
                value={selected}
                onChange={handleChange}
                input={<OutlinedInput label={label}/>}
                renderValue={(selected) =>
                    orderedDays.filter(day => selected.includes(day))
                        .map(day => italianDays[day])
                        .map(it => it.slice(0, 3))
                        .join(', ')
                }
                MenuProps={MenuProps}>
                {orderedDays.map((key) => (
                    <MenuItem key={key} value={key}>
                        <Checkbox checked={selected.indexOf(key) > -1}/>
                        <ListItemText primary={italianDays[key]}/>
                    </MenuItem>
                ))}
            </Select>
        </>
    );
}

function SelectTime({label, value, setValue, minTime, onError, sx}) {
    const _value = moment().set({hour: value[0], minute: value[1]});
    const _minTime = minTime ? moment().set({hour: minTime[0], minute: minTime[1]}) : undefined;
    return (
        <Box sx={sx}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <MobileTimePicker
                    label={label}
                    ampm={false}
                    value={_value}
                    minTime={_minTime}
                    onError={onError}
                    onChange={(newValue) => {
                        setValue([
                            newValue.hour(),
                            newValue.minute()
                        ]);
                    }}
                    renderInput={(params) => <TextField {...params} sx={{width: "100%"}}/>}
                />
            </LocalizationProvider>
        </Box>
    )
}

export default AddTimeIntervals;

import * as React from "react";
import DialplanNode from "./DialplanNode";
import TtsAnnounce from "./TtsAnnounce";

function AnswerNode() {
  return (
    <DialplanNode prop={"next"}/>
  );
}

AnswerNode.canBeReplaced = false;
AnswerNode.description = "Rispondi alla telefonata in arrivo";
AnswerNode.title = "Rispondi";
AnswerNode.default = {
  type: "ANSWER",
  text: "",
  next: TtsAnnounce.default
};


export default AnswerNode;

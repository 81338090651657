import * as React from "react";
import SouthIcon from '@mui/icons-material/South';
import {Grid} from "@mui/material";

function DownArrow() {
    return <Grid container sx={{justifyContent: "center", mt: 2, mb: 3}}>
        <SouthIcon sx={{fontSize: 50}} color={"primary"}/>
    </Grid>;
}

export default DownArrow;

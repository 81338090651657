import * as React from "react";
import { Grid, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import DownArrow from "../DownArrow";
import DialplanNode, { useDialplanNodeContext } from "./DialplanNode";
import Paper from "@mui/material/Paper";
import { useDialplanEditorContext } from "./DialplanEditorContext";
import Hangup from "./HangupNode";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Record from "./Record";

function TtsAnnounce() {
  const { node, setNode } = useDialplanNodeContext();
  const { isEditMode } = useDialplanEditorContext();
  const onTextChange = (event) => {
    setNode({ ...node, text: event.target.value });
  };
  const onVoiceChange = (event) => {
    setNode({ ...node, voiceName: event.target.value });
  };
  return (
    <>
      <Grid container direction={"column"}>
        <Grid item xs={12}>
          <div style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
          }}>
            <Typography variant="h6" align="left">
              📣 Annuncio vocale
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Typography variant="body" display={"inline"}>
            Riproduci un annuncio vocale personalizzato con la voce del tuo assistente virtuale.
          </Typography>
        </Grid>
        <Grid item container sx={{ mt: 2, mb: 2 }}>
          {isEditMode ?
            <FormControl fullWidth={true} sx={{ mt: 2 }}>
              <TextField
                sx={{ width: "100%", mt: 2 }}
                label="Testo annuncio"
                multiline
                rows={4}
                value={node.text}
                onChange={onTextChange}
                variant="outlined"
                color={"primary"}
              />

              <Typography variant="body1" sx={{ mt: 3 }}>
                Quale voce vuoi utilizzare?
              </Typography>
              <Select value={node.voiceName} onChange={onVoiceChange}>
                <MenuItem value={"ISABELLA"}>👩 Isabella</MenuItem>
                <MenuItem value={"DIEGO"}>👨 Diego</MenuItem>
              </Select>
            </FormControl>
            :
            <Paper variant="outlined" sx={{ p: 2, width: "100%" }}>
              <Typography variant="body2">
                {node.text}
              </Typography>
            </Paper>
          }
        </Grid>
      </Grid>
      <DownArrow/>
      <DialplanNode prop={"next"}/>
    </>
  );
}

TtsAnnounce.description = "Riproduci un annuncio vocale personalizzato con la voce del tuo assistente virtuale.";
TtsAnnounce.title = "Riproduci annuncio vocale";
TtsAnnounce.default = {
  type: "TTS_ANNOUNCE",
  text: "Al momento non possiamo rispondere, lascia un messaggio dopo il beep grazie.",
  voiceName: "ISABELLA",
  next: Record.default
};


export default TtsAnnounce;

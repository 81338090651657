import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import * as React from "react";

function MyAccordion({expanded, onChange, title, description, sx, children}) {
    return (
        <Accordion expanded={expanded}
                   onChange={onChange}
                   sx={{
                    ...sx,
                    width: "100%", 
                    boxShadow: 'none',
                    margin: '0px!important',
                    border: '1px solid rgba(0, 0, 0, 0.12)', 
                    '&:not(:last-child)': {
                        borderBottom: 0,
                    },
                    '&:before': {
                        display: 'none',
                    },
                }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                <div style={{display: "flex", flex: 1, gap: '1rem'}}>
                    <Typography sx={{flex: 0}}>
                        {title}
                    </Typography>
                    <Typography variant="body">{description}</Typography>
                </div>
            </AccordionSummary>
            <AccordionDetails sx={{
                padding: '1rem',
                borderTop: '1px solid rgba(0, 0, 0, .125)',
            }}>{children}</AccordionDetails>
        </Accordion>
    )
}

export default MyAccordion;

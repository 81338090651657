import TtsAnnounce from "./TtsAnnounce";
import Hangup from "./HangupNode";
import Record from "./Record";
import AnswerNode from "./AnswerNode";
import ListRouting from "./ListRoutingNode";
import TimeSwitchNode from "./TimeSwitchNode";
import DialNode from "./DialNode";
import IVRNode from "./IVRNode";

export const NODES = {
    "ANSWER": AnswerNode,
    "TTS_ANNOUNCE": TtsAnnounce,
    "HANGUP": Hangup,
    "RECORD": Record,
    "LIST_ROUTING": ListRouting,
    "TIME_SWITCH": TimeSwitchNode,
    "DIAL": DialNode,

    // "OPENING_TIME": OpeningTimeNode,
    //"FILE_ANNOUNCE": FileAnnounce,

    "IVR": IVRNode,
}

import {QueryClient, QueryClientProvider,} from '@tanstack/react-query'

const queryClient = new QueryClient()

export default function RepositoryContext({children}) {
    return (
        <QueryClientProvider client={queryClient}>
            {children}
        </QueryClientProvider>
    )
}

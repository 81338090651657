import * as React from "react";
import Typography from "@mui/material/Typography";

function LoginScreen() {
    return (
        <div style={{
            flexDirection: "column",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 300,
            padding: 30
        }}>
            <Typography variant="h5" align={"center"}>
                Accedi all'app mySegreteria per gestire il tuo risponditore.
            </Typography>
        </div>
    )
}

export default LoginScreen;

import {useMutation, useQuery, useQueryClient,} from '@tanstack/react-query'
import {BASE_API_URL} from "../Config";
import axios from "axios";
import useAuthentication from "../service/AuthenticationService";

const instance = axios.create({
    timeout: 30_000,
    headers: {
        "Content-Type": "application/json",
    }
});


export function useDialplanRepository() {
    const authentication = useAuthentication();
    const queryClient = useQueryClient()

    const {isLoading, error, data} = useQuery({
        queryKey: ['dialplan'],
        queryFn: async () => {
            const results = await instance.get(BASE_API_URL + "/dialplan/"+authentication.phoneUuid, {
                headers: {
                    "Authorization": "Bearer " + authentication.token
                }
            });
            return results.data
        },
        staleTime: 1000 * 60 * 5,
    })

    const putMutation = useMutation({
        mutationFn: (dialplan) => instance.put(BASE_API_URL + "/dialplan/" + authentication.phoneUuid, dialplan, {
            headers: {
                "Authorization": "Bearer " + authentication.token
            }
        }),
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['dialplan']})
        },
    })

    return {isLoading, error, data, putMutation}
}

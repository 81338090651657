import * as React from "react";
import { FormControlLabel, FormGroup, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import CallEndIcon from "@mui/icons-material/CallEnd";
import MicIcon from "@mui/icons-material/Mic";
import DownArrow from "../DownArrow";
import DialplanNode, { useDialplanNodeContext } from "./DialplanNode";
import Hangup from "./HangupNode";
import { useDialplanEditorContext } from "./DialplanEditorContext";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";

function Record() {
  const { node, setNodeChild } = useDialplanNodeContext();
  const { isEditMode } = useDialplanEditorContext();

  const silenceSeconds = node.silenceSeconds || 5;

  return (
    <>
      <Grid container direction={"column"} alignItems={"center"}>
        <MicIcon sx={{ fontSize: 70 }} color={"error"}/>
        <Grid item xs={12} sx={{ mb: 2, mt: 2 }} align={"center"}>
          <Typography variant="body">
            Registra un messaggio dopo il beep.
          </Typography>
          <Typography sx={{ mt: 1 }}>
            La registrazione termina dopo{" "}
            {!isEditMode && (silenceSeconds + " second" + (silenceSeconds === 1 ? "o" : "i") + " di silenzio.")}
            {isEditMode && <><EditSeconds value={silenceSeconds} onChange={setNodeChild("silenceSeconds")}/> di
              silenzio.</>}
          </Typography>
        </Grid>
        <DownArrow/>
      </Grid>
      {!node.next
        ? <Grid container direction={"column"} alignItems={"center"}>
          <CallEndIcon sx={{ fontSize: 70 }} color={"error"}/>
          <Grid item xs={12} sx={{ mb: 2, mt: 2 }}>
            <Typography variant="body" display={"inline"} align={"center"}>
              La chiamata viene terminata e ricevi il messaggio registrato in app.
            </Typography>
          </Grid>
        </Grid>
        : <DialplanNode prop={"next"}/>
      }
    </>
  );
}

function EditSeconds({ value, onChange }) {
  return (
    <Select
      value={value}
      onChange={(e) => onChange(e.target.value)}>
      {Array.from({ length: 6 }, (_, i) => i + 3).map(i => (
        <MenuItem value={i}>{i} second{i === 1 ? "o" : "i"}</MenuItem>))}
    </Select>
  );
}

function RepeatCheckbox({ value, onChange }) {
  return (
    <FormGroup>
      <Grid alignContent={"center"}>
        <FormControlLabel control={<Checkbox checked={value} onChange={(e) => onChange(e.target.checked)}/>}
                          label={"Ripeti il messaggio in caso di silenzio"}/>
      </Grid>
    </FormGroup>
  );

}

Record.title = "Registra un messaggio";
Record.description = "Permetti a chi chiama di lasciare un messaggio dopo il beep";
Record.default = {
  type: "RECORD",
  repeatPreviousNodeOnFailure: true,
  silenceSeconds: 4,
  next: Hangup.default
};


export default Record;
